import React from 'react';

const ContactUs = () => {
  const styles = {
    container: {
      padding: '40px',
      backgroundColor: '#ffffff',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      margin: '20px auto',
      animation: 'fadeIn 0.5s',
    },
    heading: {
      color: '#60a5fa',
      fontSize: '2.5rem',
      marginBottom: '20px',
      textAlign: 'center',
    },
    paragraph: {
      lineHeight: 1.8,
      marginBottom: '15px',
      fontSize: '1rem',
      color: '#555',
    },
    link: {
      color: '#60a5fa',
      textDecoration: 'none',
      transition: 'color 0.3s',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Contact Us</h1>
      <p style={styles.paragraph}>If you have any questions or feedback, please reach out to us at:</p>
      <p style={styles.paragraph}>Email: <a href="mailto:vivekkumardhigarla24@gmail.com" style={styles.link}>vivekkumardhigarla24@gmail.com</a></p>
    </div>
  );
};

export default ContactUs;