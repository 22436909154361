import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import reportWebVitals from './reportWebVitals';
import LandingPage from './LandingPage';
import SubscriptionPage from './SubscriptionPage';
import App from './App';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';


const firebaseConfig = {
  apiKey: "AIzaSyAkyqaMjNHYkmi5xfgewK0C7w77Wm8cv7Q",
  authDomain: "olivia-c9302.firebaseapp.com",
  projectId: "olivia-c9302",
  storageBucket: "olivia-c9302.appspot.com",
  messagingSenderId: "445600026476",
  appId: "1:445600026476:web:b67ab0ebd56de025b4ad49",
  measurementId: "G-KPVRB1X2BJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage analytics={analytics} />} />
        <Route path="/subscription" element={<SubscriptionPage analytics={analytics} />} />
        <Route 
          path="/chat" 
          element={
            
              <App analytics={analytics} />
            
          } 
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
