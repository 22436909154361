import React from 'react';

const TermsOfService = () => {
  const styles = {
    container: {
      padding: '40px',
      backgroundColor: '#ffffff',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      margin: '20px auto',
      animation: 'fadeIn 0.5s',
    },
    heading: {
      color: '#60a5fa',
      fontSize: '2.5rem',
      marginBottom: '20px',
      textAlign: 'center',
    },
    subheading: {
      color: '#4a4a4a',
      fontSize: '1.8rem',
      marginTop: '30px',
    },
    paragraph: {
      lineHeight: 1.8,
      marginBottom: '15px',
      fontSize: '1rem',
      color: '#555',
    },
    link: {
      color: '#60a5fa',
      textDecoration: 'none',
      transition: 'color 0.3s',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Terms of Service</h1>
      <p style={styles.paragraph}>Welcome to Phymech. By using our services, you agree to the following terms and conditions.</p>
      <h2 style={styles.subheading}>Acceptance of Terms</h2>
      <p style={styles.paragraph}>By accessing or using our services, you agree to be bound by these Terms of Service.</p>
      <h2 style={styles.subheading}>Use of Services</h2>
      <p style={styles.paragraph}>You agree to use our services only for lawful purposes and in accordance with these terms.</p>
      <h2 style={styles.subheading}>Intellectual Property</h2>
      <p style={styles.paragraph}>All content, trademarks, and other intellectual property on our website are owned by us or our licensors.</p>
      <h2 style={styles.subheading}>Limitation of Liability</h2>
      <p style={styles.paragraph}>We are not liable for any damages arising from your use of our services.</p>
      <h2 style={styles.subheading}>Changes to Terms</h2>
      <p style={styles.paragraph}>We may update these Terms of Service from time to time. Your continued use of our services after any changes constitutes your acceptance of the new terms.</p>
      <h2 style={styles.subheading}>Contact Us</h2>
      <p style={styles.paragraph}>If you have any questions about these Terms of Service, please contact us at <a href="mailto:vivekkumardhigarla24@gmail.com" style={styles.link}>vivekkumardhigarla24@gmail.com</a>.</p>
      <h2 style={styles.subheading}>Refund</h2>
      <p style={styles.paragraph}>Refund will be provided if the user is not satisfied with the service within 7 days of purchase. <a href="mailto:vivekkumardhigarla24@gmail.com" style={styles.link}>vivekkumardhigarla24@gmail.com</a>.</p>
    </div>
  );
};

export default TermsOfService;