import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import axios from 'axios';
import { Link } from 'react-router-dom';
import './App.css';


const Feature = ({ icon, title, description }) => (
  <motion.div 
    className="feature"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6 }}
  >
    <div className="icon-wrapper">
      {typeof icon === 'string' ? (
        <img src={icon} alt={title} className="feature-image" />
      ) : (
        icon
      )}
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const PricingTier = ({ title, price, originalPrice, duration, features, isPopular, onClick }) => {
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const endDate = new Date('2024-11-19T00:00:00Z'); 
    
    const updateTimer = () => {
      const now = new Date();
      const difference = endDate - now;
      
      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        setTimeLeft({ hours, minutes, seconds });
      } else {
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      }
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = ({ hours, minutes, seconds }) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <motion.div 
      className={`pricing-tier ${isPopular ? 'popular' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      {isPopular && <div className="popular-badge">Most Popular</div>}
      <h3>{title}</h3>
      <p className="price">
        <span className="original-price">₹{originalPrice}</span>
        <span className="discounted-price">₹{price}</span>
        <span>/{duration}</span>
      </p>
      <p className="offer-ends">Offer ends in: {formatTime(timeLeft)}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={20} />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button onClick={onClick}>Get Started</button>
    </motion.div>
  );
};

const DynamicConversation = () => {
  const [conversation, setConversation] = useState({ chris: '', olivia: '' });
  const [pairIndex, setPairIndex] = useState(0);
  const [typing, setTyping] = useState('chris');

  useEffect(() => {
    const messagePairs = [
      {
        chris: "I'm nervous about asking them out.",
        olivia: "Good. That means it matters. Now, instead of worrying, let's craft a message that's impossible to ignore."
      },
      {
        chris: "I always mess up when texting someone I like. What should I do?",
        olivia: "Let's start by owning it. It's not about perfect texts, it's about making them want to keep the conversation going. I'll show you how."
      },
      {
        chris: "Hey, coffee went well. What's next?",
        olivia: "Great! Now's the time to keep the momentum going. Follow up, but don't overthink it. I'll help you send the perfect message to leave them wanting more."
      }
    ];

    let timer;
    const currentPair = messagePairs[pairIndex];

    const typeMessage = (sender, message, index = 0) => {
      if (index <= message.length) {
        setConversation(prev => ({
          ...prev,
          [sender]: message.slice(0, index)
        }));
        timer = setTimeout(() => typeMessage(sender, message, index + 1), 30);
      } else if (sender === 'chris') {
        setTyping('olivia');
        timer = setTimeout(() => typeMessage('olivia', currentPair.olivia), 500);
      } else {
        timer = setTimeout(() => {
          setPairIndex((pairIndex + 1) % messagePairs.length);
          setTyping('chris');
          setConversation({ chris: '', olivia: '' });
        }, 2000);
      }
    };

    typeMessage('chris', currentPair.chris);

    return () => clearTimeout(timer);
  }, [pairIndex]);

  return (
    <div className="dynamic-conversation">
      <AnimatePresence mode="wait">
        <motion.div
          key={pairIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <div className="chris-message">
            <span className="sender">Chris: </span>
            <span className="message">
              {conversation.chris}
              {typing === 'chris' && <span className="cursor chris" />}
            </span>
          </div>
          <div className="olivia-message">
            <span className="sender">Olivia: </span>
            <span className="message">
              {conversation.olivia}
              {typing === 'olivia' && <span className="cursor olivia" />}
            </span>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default function LandingPage({ analytics }) {
  const [user, setUser] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const auth = getAuth();
  
  

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000); // Hide after 5 seconds
      }
    });

    return () => {
      unsubscribe();
      setShowNotification(false); // Reset notification state when component unmounts
    };
  }, [auth]);

  useEffect(() => {
    // Log page view
    if (analytics && typeof analytics.logEvent === 'function') {
      analytics.logEvent('page_view', {
        page_title: 'Landing Page',
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }, [analytics]);

  const handleGetStarted = async () => {
    if (!user) {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);
        // After successful sign-in, show a notification
        setShowNotification(true);
        return; // Exit the function here
      } catch (error) {
        console.error("Error signing in:", error);
        return;
      }
    }

    try {
      // Check subscription status after signing in
      const token = await user.getIdToken();
      const response = await axios.get('/.netlify/functions/api/check-subscription', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.hasActiveSubscription) {
        // Redirect to chat if subscription is active
        window.location.href = '/chat';
      } else {
        // Redirect to subscription page if no active subscription
        window.location.href = '/subscription';
      }
      // Optionally, handle the response (e.g., update state or show messages)
    } catch (error) {
      console.error("Error checking subscription:", error);
    }
  };

  return (
    <div className="landing-page">
      
      {showNotification && (
        <div className="notification">
          <p>You have logged in! Click on "Start Your Journey"</p>
        </div>
      )}
      <main>
        <section className="hero">
          <div className="container">
            <motion.h1 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Olivia, Your AI Relationship Advisor
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Boost your Relationship game with personalized advice and strategies
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="conversation-wrapper"
            >
              <DynamicConversation />
            </motion.div>
            <motion.button 
              className="cta-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleGetStarted}
            >
              {user ? 'Start Your Journey' : 'Sign In to Get Started'}
            </motion.button>
          </div>
        </section>

        <section id="features" className="features">
          <div className="container">
            <h2>Why Choose Olivia?</h2>
            <div className="features-grid">
              <Feature 
                icon="/olivia.jpg"
                title="Personalized Advice"
                description="Get tailored strategies based on your personality and goals from Olivia"
              />
              <Feature 
                icon="/olivia1.jpg"
                title="24/7 Support"
                description="Olivia is always here to help you navigate your whole journey"
              />
              <Feature 
                icon="/olivia2.jpg"
                title="Boost Confidence"
                description="Learn techniques to approach Relationships with confidence and authenticity"
              />
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container">
            <h2>What Our Users Say</h2>
            <div className="testimonials-grid">
              <motion.div 
                className="testimonial"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <p>"As a introvert Olivia helped me overcome my Relationship anxiety!"</p>
                <p className="author">- Varun, 23</p>
              </motion.div>
              <motion.div 
                className="testimonial"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <p>"The personalized advice was game-changing. I feel so much more confident now."</p>
                <p className="author">- Sam, 24</p>
              </motion.div>
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing">
          <div className="container">
            <h2>Choose Your Plan</h2>
            <div className="pricing-grid">
              <PricingTier 
                title="3 Months"
                price={999}
                originalPrice={3999}
                duration="3 months"
                features={["Personalized advice", "100 messages daily", "No generic BS", "24/7 chat support"]}
                onClick={handleGetStarted}
              />
              <PricingTier 
                title="Lifetime"
                price={4999}
                originalPrice={11000}
                duration="lifetime"
                features={["Personalized advice", "Unlimited messages", "No generic BS", "Priority support", "Future updates included"]}
                isPopular={true}
                onClick={handleGetStarted}
              />
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container">
          <p>&copy; 2024 Phymech. All rights reserved.</p>
          <div className="footer-links">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
           <Link to="/contact-us">Contact Us</Link>
           <Link to="/about-us">About Us</Link>
          </div>
        </div>
      </footer>

      <style jsx>{`
        /* Global Styles */
        :global(body) {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          line-height: 1.6;
          color: #e0e0e0;
          background-color: #121212;
          margin: 0;
          padding: 0;
          overflow-x: hidden;
          width: 100%;
        }
        html, body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
        
        
        .container {
          margin: 0 auto;
          max-width: 100%;
          padding: 0 20px;
}
        h1, h2, h3 {
          margin-bottom: 20px;
          color: #ffffff;
        }

        /* Landing Page */
        .landing-page {
          min-height: 100vh;
        }

        /* Hero Section */
        .hero {
          text-align: center;
          padding: 8px 0;
          background-color: #1a1a1a;
        }

        .hero h1 {
          font-size: 48px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #bb86fc;
        }

        .hero p {
          font-size: 20px;
          color: #b0bec5;
          margin-bottom: 40px;
        }

        .conversation-wrapper {
          margin-bottom: 40px;
        }
        

        .cta-button {
          background-color: #bb86fc;
          color: #000000;
          font-size: 18px;
          font-weight: 600;
          padding: 12px 24px;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .cta-button:hover {
          background-color: #9966cc;
          box-shadow: 0 4px 8px rgba(187, 134, 252, 0.3);
          transform: translateY(-2px);
        }

        /* Dynamic Conversation */
        .dynamic-conversation {
          background-color: #1e1e1e;
          padding: 32px;
          border-radius: 16px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          max-width: 800px;
          margin: 0 auto;
          text-align: left;
        }

        .chris-message, .olivia-message {
          margin-bottom: 24px;
        }

        .sender {
          font-weight: bold;
          font-size: 20px;
        }

        .chris-message .sender {
          color: #64b5f6;
        }

        .olivia-message .sender {
          color: #bb86fc;
        }

        .message {
          display: block;
          margin-top: 8px;
          font-size: 18px;
        }

        .cursor {
          display: inline-block;
          width: 8px;
          height: 20px;
          margin-left: 4px;
          vertical-align: middle;
          animation: blink 0.7s infinite;
        }

        .cursor.chris {
          background-color: #64b5f6;
        }

        .cursor.olivia {
          background-color: #bb86fc;
        }

        @keyframes blink {
          0% { opacity: 0; }
          50% { opacity: 1; }
          100% { opacity: 0; }
        }

        /* Features Section */
        .features {
          padding: 80px 0;
          background-color: #1a1a1a;
          text-align: center;
        }

        .features-grid {
          display: flex;
          justify-content: center;
          gap: 40px;
          flex-wrap: wrap;
        }

        .feature {
          background-color: #2a2a2a;
          padding: 20px;
          border-radius: 16px;
          max-width: 300px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .feature:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(187, 134, 252, 0.1);
        }

        .feature .icon-wrapper {
          width: 60px;
          height: 60px;
          margin: 0 auto 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #bb86fc;
          border-radius: 50%;
          overflow: hidden;
        }

        .feature .icon-wrapper img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .feature h3 {
          color: #bb86fc;
          margin-top: 10px;
        }

        .feature p {
          color: #b0bec5;
        }

        /* Testimonials Section */
        .testimonials {
          padding: 80px 0;
          background-color: #1e1e1e;
          text-align: center;
        }

        .testimonials-grid {
          display: flex;
          justify-content: center;
          gap: 40px;
          flex-wrap: wrap;
        }

        .testimonial {
          background-color: #2a2a2a;
          padding: 20px;
          border-radius: 16px;
          max-width: 300px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .testimonial:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(187, 134, 252, 0.1);
        }

        .testimonial p {
          color: #b0bec5;
        }

        .testimonial .author {
          margin-top: 10px;
          color: #bb86fc;
        }

        /* Pricing Section */
        .pricing {
          padding: 80px 0;
          background-color: #1a1a1a;
          text-align: center;
        }

        .pricing-grid {
          display: flex;
          justify-content: center;
          gap: 20px;
          flex-wrap: wrap;
        }

        .pricing-tier {
          background-color: #2a2a2a;
          padding: 20px;
          border-radius: 16px;
          width:300px;
          max-width: 300px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .pricing-tier.popular {
          border: 2px solid #bb86fc;
        }

        .pricing-tier:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(187, 134, 252, 0.1);
        }

        .pricing-tier h3 {
          color: #bb86fc;
        }

        .pricing-tier .price {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
        }

        .pricing-tier .price span {
          font-size: 16px;
          color: #b0bec5;
        }

        .pricing-tier ul {
          list-style-type: none;
          padding: 0;
          color: #b0bec5;
        }

        .pricing-tier li {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-bottom: 10px;
        }

        .pricing-tier button {
          background-color: #bb86fc;
          color: #000000;
          font-size: 18px;
          font-weight: 600;
          padding: 12px 24px;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .pricing-tier button:hover {
          background-color: #9966cc;
          box-shadow: 0 4px 8px rgba(187, 134, 252, 0.3);
          transform: translateY(-2px);
        }
        /* Footer */
        footer {
          padding: 40px 0;
          background-color: #121212;
          text-align: center;
        }

        footer p {
          color: #b0bec5;
        }

        .footer-links {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-top: 20px;
        }

        .footer-links a {
          color: #bb86fc;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .footer-links a:hover {
          color: #9966cc;
        }

        /* Media Queries */
        @media (max-width: 768px) {
  .container, .hero, .dynamic-conversation {
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero h1 {
    font-size: 40px;
  }

  .hero p {
    font-size: 18px;
  }

  .feature, .testimonial, .pricing-tier {
    padding: 15px;
  }

  .feature h3, .testimonial p, .pricing-tier h3 {
    font-size: 18px;
  }

  .feature p, .testimonial .author, .pricing-tier .price {
    font-size: 14px;
  }

  .features-grid, .testimonials-grid, .pricing-grid {
    flex-direction: column;
    align-items: center;
  }

  .feature, .testimonial, .pricing-tier {
    width: 100%;
    margin-bottom: 20px;
  }
}

        .notification {
          position: fixed;
          top: 20px;
          right: 20px;
          background-color: #4caf50; /* Green background */
          color: white; /* White text */
          padding: 15px;
          border-radius: 5px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          z-index: 1000; /* Ensure it appears above other elements */
          animation: fadeIn 0.5s, fadeOut 0.5s 4.5s; /* Fade in and out */
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; }
        }
      `}</style>
    </div>
  );
}
