import React from 'react';

const AboutUs = () => {
  const styles = {
    container: {
      padding: '40px',
      backgroundColor: '#ffffff',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      margin: '20px auto',
      animation: 'fadeIn 0.5s',
    },
    heading: {
      color: '#60a5fa',
      fontSize: '2.5rem',
      marginBottom: '20px',
      textAlign: 'center',
    },
    paragraph: {
      lineHeight: 1.8,
      marginBottom: '15px',
      fontSize: '1rem',
      color: '#555',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>About Us</h1>
      <p style={styles.paragraph}>
        Olivia is an AI-powered relationship advisor created by Phymech. Our mission is to provide personalized, 
        insightful guidance to help individuals navigate the complexities of modern relationships.
      </p>
      <p style={styles.paragraph}>
        At Phymech, we believe that everyone deserves access to quality relationship advice. That's why we've 
        developed Olivia, an advanced AI that combines the latest in machine learning technology with 
        expert knowledge in relationship psychology.
      </p>
      <p style={styles.paragraph}>
        Olivia is designed to be your 24/7 companion, offering tailored advice, strategies, and support 
        to help you build healthier, more fulfilling relationships. Whether you're dealing with dating 
        challenges, looking to improve your communication skills, or seeking guidance on complex relationship 
        issues, Olivia is here to help.
      </p>
      <p style={styles.paragraph}>
        Our team at Phymech is committed to continuously improving Olivia's capabilities, ensuring that 
        you receive the most up-to-date and effective relationship advice possible.
      </p>
    </div>
  );
};

export default AboutUs;
