import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      padding: '40px',
      backgroundColor: '#ffffff',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      margin: '20px auto',
      animation: 'fadeIn 0.5s',
    },
    heading: {
      color: '#60a5fa',
      fontSize: '2.5rem',
      marginBottom: '20px',
      textAlign: 'center',
    },
    subheading: {
      color: '#4a4a4a',
      fontSize: '1.8rem',
      marginTop: '30px',
    },
    paragraph: {
      lineHeight: 1.8,
      marginBottom: '15px',
      fontSize: '1rem',
      color: '#555',
    },
    link: {
      color: '#60a5fa',
      textDecoration: 'none',
      transition: 'color 0.3s',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}>At Phymech, we value your privacy. This Privacy Policy outlines how we collect, use, and protect your information.</p>
      <h2 style={styles.subheading}>Information We Collect</h2>
      <p style={styles.paragraph}>We collect personal information that you provide to us when you register, subscribe, or interact with our services. This may include your name, email address, and payment information.</p>
      <h2 style={styles.subheading}>How We Use Your Information</h2>
      <p style={styles.paragraph}>Your information is used to provide and improve our services, communicate with you, and process transactions.</p>
      <h2 style={styles.subheading}>Data Protection</h2>
      <p style={styles.paragraph}>We implement appropriate security measures to protect your personal information from unauthorized access, use, or disclosure.</p>
      <h2 style={styles.subheading}>Changes to This Policy</h2>
      <p style={styles.paragraph}>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>
      <h2 style={styles.subheading}>Contact Us or Refund</h2>
      <p style={styles.paragraph}>If you have any questions about this Privacy Policy or want a refund, please contact us at <a href="mailto:vivekkumardhigarla24@gmail.com" style={styles.link}>vivekkumardhigarla24@gmail.com</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;