import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Send } from 'lucide-react';
import { getAuth } from "firebase/auth";
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        checkSubscription(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user && hasActiveSubscription) {
      fetchPreviousMessages(user.email);
    }
  }, [user, hasActiveSubscription]);

  const checkSubscription = async (user) => {
    try {
      const token = await user.getIdToken();
      const response = await axios.get('/.netlify/functions/api/check-subscription', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setHasActiveSubscription(response.data.hasActiveSubscription);
      
      if (!response.data.hasActiveSubscription) {
        window.location.href = '/subscription';
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async (e) => {
    if (e) e.preventDefault();
    if (!input.trim()) return;

    const userMessage = input.trim();
    setMessages(prev => [...prev, { text: userMessage, isUser: true, timestamp: new Date() }]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('/.netlify/functions/api/chat', { 
        prompt: userMessage,
        userEmail: user.email
      });
      const botMessage = response.data.message;

      setMessages(prev => [...prev, { text: botMessage, isUser: false, timestamp: new Date() }]);

      // Save the user's message and bot's response
      await saveMessage(user.email, { text: userMessage, isUser: true, timestamp: new Date() });
      await saveMessage(user.email, { text: botMessage, isUser: false, timestamp: new Date() });
    } catch (error) {
      console.error('Error fetching the response:', error);
      setMessages(prev => [...prev, { text: "Error getting response from server.", isUser: false, timestamp: new Date() }]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPreviousMessages = async (userEmail) => {
    try {
      const response = await axios.get(`/.netlify/functions/api/messages/${userEmail}`);
      const fetchedMessages = response.data.messages;

      if (fetchedMessages.length === 0) {
        const welcomeMessage = {
          text: "Hey there! I am excited to be your personal dating coach. I know dating can be tricky, but I am here to help you find your way. To start, could you tell me your name and what you are looking for in your dating life right now? Whether its overcoming a recent challenge or just looking for some advice, I am here to listen and guide you. Lets figure this out together!",
          isUser: false,
          timestamp: new Date()
        };
        await saveMessage(userEmail, welcomeMessage);
        setMessages([welcomeMessage]);
      } else {
        setMessages(fetchedMessages.map(msg => ({
          ...msg,
          timestamp: msg.timestamp ? new Date(msg.timestamp) : new Date()
        })));
      }
    } catch (error) {
      console.error('Error fetching previous messages:', error);
    }
  };

  const saveMessage = async (userEmail, message) => {
    try {
      await axios.post('/.netlify/functions/api/save-message', {
        userEmail,
        message: {
          ...message,
          timestamp: message.timestamp.toISOString()
        }
      });
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  if (!user) {
    return <div>Please sign in to access this page.</div>;
  }

  if (!hasActiveSubscription) {
    return <div>You need an active subscription to access this feature. Please <a href="/subscription">subscribe</a>.</div>;
  }

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="chat-header-left">
          <div className="profile-image">
            <img src="/olivia.jpg" alt="AI Assistant" />
          </div>
          <div className="header-text">
            <h1>Olivia</h1>
            <p>Always there for you!</p>
          </div>
        </div>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'user' : 'bot'}`}>
            <div className="message-content">
              <div className="text">{message.text}</div>
            </div>
            <div className="message-time">
              {formatTimestamp(message.timestamp)}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="message bot">
            <div className="message-content">
              <div className="text typing-indicator"><span></span><span></span><span></span></div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={sendMessage} className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message here..."
        />
        <button type="submit">
          <Send />
        </button>
      </form>
    </div>
  );
};

export default App;
